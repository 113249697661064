import request from '@/utils/request'

export const getBundleSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/shop_item_bundles/setup'
  })
}

export const getBundleList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/shop_item_bundles',
    params
  })
}

export const createBundle = (data) => {
  return request({
    method: 'POST',
    url: '/cms/shop_item_bundles',
    data
  })
}

export const editBundle = (id, data) => {
  return request({
    method: 'PUT',
    url: `/cms/shop_item_bundles/${id}`,
    data
  })
}

export const getBundleDetail = (id) => {
  return request({
    method: 'GET',
    url: `/cms/shop_item_bundles/${id}`
  })
}

export const deleteBundle = (id) => {
  return request({
    method: 'DELETE',
    url: `/cms/shop_item_bundles/${id}`
  })
}
export const getBundleEditDetail = (id) => {
  return request({
    method: 'GET',
    url: `/cms/shop_item_bundles/${id}/preview`
  })
}

export const getBundleProductSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/bundle_items/setup'
  })
}

export const getBundleProductList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/bundle_items',
    params
  })
}

export const createBundleProduct = (data) => {
  return request({
    method: 'POST',
    url: '/cms/bundle_items',
    data
  })
}

export const postBundleItemsIdOnline = (id) => {
  return request({
    method: 'POST',
    url: `/cms/shop_item_bundles/${id}/online`
  })
}

export const postBundleItemsIdOffline = (id) => {
  return request({
    method: 'POST',
    url: `/cms/shop_item_bundles/${id}/offline`
  })
}
